import React, { useState, useEffect, Fragment } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useStore } from '@nanostores/react'
import { isMobileMenuOpen } from '@stores/menuStore'
import { userData } from '@stores/userStore'
import Hamburger from '@components/Icons/Hamburger.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import DisneyLogo from '@components/Icons/DisneyLogo.jsx'
import Heart from '@components/Icons/Heart.jsx'
import Location from '@components/Icons/Location.jsx'
import User from '@components/Icons/User.jsx'
import UserSolid from '@components/Icons/UserSolid.jsx'
import EmbroideredBlanket from '@components/Icons/EmbroideredBlanket.jsx'
import Calendar from '@components/Icons/Calendar.jsx'
import Document from '@components/Icons/Document.jsx'
import OpenBox from '@components/Icons/OpenBox.jsx'
import Gift from '@components/Icons/Gift.jsx'
import Chat from '@components/Icons/Chat.jsx'
import SubnavMaternity from './SubnavMaternity.jsx'
import SubnavBaby from './SubnavBaby.jsx'
import SubnavToddler from './SubnavToddler.jsx'
import SubnavKids from './SubnavKids.jsx'
import SubnavNursery from './SubnavNursery.jsx'
import SubnavGear from './SubnavGear.jsx'
import SubnavGifts from './SubnavGifts.jsx'
import SubnavHelp from './SubnavHelp.jsx'
import SubnavAccount from './SubnavAccount.jsx'

import * as nav from '@data/navdata'

const NavMobileMenu = () => {
    const $isMobileMenuOpen = useStore(isMobileMenuOpen)
    const $userData = useStore(userData || null)

    const [haveUser, setHaveUser] = useState(false)

    useEffect(() => {
        if ($userData && $userData.isLoggedIn) {
            setHaveUser(true)
        } else {
            setHaveUser(false)
        }
    }, [$userData])

    const [activeSubnav, setActiveSubnav] = useState({
        maternity: false,
        baby: false,
        toddler: false,
        kids: false,
        nursery: false,
        gear: false,
        gifts: false,
        help: false,
        account: false,
    })

    const [activeSubSubnav, setActiveSubSubnav] = useState({
        maternity_moments: false,
        maternity_mom: false,
        baby_moments: false,
        baby_sizes: false,
        kids_moments: false,
        kids_sizes: false,
        health_safety: false,
        furniture_decor: false,
        nursery_moments: false,
        gear_moments: false,
        gift_bundles: false,
        gift_guides: false,
    })

    const handleMenuToggle = () => {
        isMobileMenuOpen.set(!$isMobileMenuOpen)

        const main = document.querySelector('main')
        const menuOpen = isMobileMenuOpen.get()

        if (menuOpen) {
            main.classList.add('menu-overlay')

            setActiveSubnav({
                maternity: false,
                baby: false,
                kids: false,
                toddler: false,
                nursery: false,
                gear: false,
                gifts: false,
                help: false,
                account: false,
            })

            setActiveSubSubnav({
                maternity_moments: false,
                maternity_mom: false,
                baby_moments: false,
                baby_nursery: false,
                baby_sizes: false,
                toddler_moments: false,
                toddler_sizes: false,
                kids_moments: false,
                kids_sizes: false,
                health_safety: false,
                nursery_moments: false,
                furniture_decor: false,
                gear_nursing: false,
                gear_moments: false,
                gift_bundles: false,
                gift_guides: false,
            })
        } else {
            main.classList.remove('menu-overlay')
        }
    }

    const handleToggleSubnav = (e) => {
        const nav = e.currentTarget.dataset.nav

        setActiveSubnav({
            ...activeSubnav,
            [nav]: !activeSubnav[nav],
        })
    }

    const handleToggleSubSubnav = (e) => {
        const nav = e.currentTarget.dataset.subnav

        setActiveSubSubnav({
            ...activeSubSubnav,
            [nav]: !activeSubSubnav[nav],
        })
    }

    const handleSwiped = (e) => {
        console.log('e', e)

        if (e.dir === 'Left') {
            isMobileMenuOpen.set(false)

            const main = document.querySelector('main')
            const menuOpen = isMobileMenuOpen.get()
            main.classList.remove('menu-overlay')
        }
    }

    const menuHandlers = useSwipeable({
        onSwiped: handleSwiped,
        touchEventOptions: { passive: false },
    })

    return (
        <div className='mobile-menu-wrap' {...menuHandlers}>
            <div
                className='menu-toggle mr-2 flex-items-center ml-3'
                onClick={handleMenuToggle}
            >
                <span className='screen-reader-text sr-only'>Menu</span>
                {$isMobileMenuOpen ? (
                    <span className='close-x menu-close relative top-[1px]'>
                        <CloseX width='w-[18px]' />
                    </span>
                ) : (
                    <span className='bars hamburger-menu relative top-[1px]'>
                        <Hamburger width='w-[18px]' />
                    </span>
                )}

                <span className='times hidden'>&times;</span>
            </div>
            <div
                className={`mobile-menu-outer absolute top-[64px] left-0 w-10/12 bg-[#FFFAF5] p-3 h-auto z-50 ${
                    $isMobileMenuOpen ? 'flex' : 'hidden'
                }`}
            >
                <div className='mobile-menu-inner w-full'>
                    <nav className='mobile-menu w-full'>
                        <div className='mobile-menu-sections w-full'>
                            <div className='mobile-menu-section mobile-menu-baby w-full'>
                                <div
                                    className='menu-section-header flex items-center w-full py-2'
                                    data-nav='baby'
                                >
                                    <a href='/collections/baby' rel='prefetch'>
                                        <span className='menu-section-text '>
                                            Baby (0–24M)
                                        </span>{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='mobile-menu-section mobile-menu-toddler w-full'>
                                <div
                                    className='menu-section-header flex items-center w-full py-2'
                                    data-nav='toddler'
                                >
                                    <a
                                        href='/collections/toddler'
                                        rel='prefetch'
                                    >
                                        <span className='menu-section-text '>
                                            Toddler (2T–5T)
                                        </span>{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='mobile-menu-section mobile-menu-kids w-full'>
                                <div
                                    className='menu-section-header flex items-center w-full py-2'
                                    data-nav='kids'
                                >
                                    <a href='/collections/kids' rel='prefetch'>
                                        <span className='menu-section-text '>
                                            Kids (6T-10Y)
                                        </span>{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='mobile-menu-section mobile-menu-maternity w-full'>
                                <div
                                    className='menu-section-header flex items-center w-full py-2'
                                    data-nav='adult'
                                >
                                    <a
                                        href='/collections/adults'
                                        rel='prefetch'
                                    >
                                        <span className='menu-section-text '>
                                            Adult
                                        </span>{' '}
                                    </a>
                                </div>
                            </div>
                            <div className='mobile-menu-section mobile-menu-nursery w-full'>
                                <div
                                    className='menu-section-header flex items-center w-full py-2'
                                    data-nav='all-items'
                                >
                                    <a
                                        href='/collections/all-items'
                                        rel='prefetch'
                                    >
                                        <span className='menu-section-text '>
                                            All Items
                                        </span>{' '}
                                    </a>
                                </div>
                            </div>

                            <div className='mobile-menu-section mobile-menu-help w-full border-t border-oat'>
                                <div
                                    className='menu-section-header flex items-center w-full py-2'
                                    data-nav='help'
                                    onClick={handleToggleSubnav}
                                >
                                    <div className='menu-icon w-[18px] flex items-center justify-center'>
                                        <Chat width='w-[14px]' />
                                    </div>
                                    <span className='menu-section-text ml-2'>
                                        Help + Support
                                    </span>{' '}
                                    <span className='ml-auto'>
                                        {activeSubnav &&
                                        activeSubnav.help === true ? (
                                            <Minus width='w-[16px]' />
                                        ) : (
                                            <Plus width='w-[18px]' />
                                        )}
                                    </span>
                                </div>
                                <div
                                    className={`subnav subnav-help ${
                                        activeSubnav && activeSubnav.help
                                            ? 'block'
                                            : 'hidden'
                                    }`}
                                    data-subnav='help'
                                >
                                    <SubnavHelp
                                        activeSubSubnav={activeSubSubnav}
                                        setActiveSubSubnav={setActiveSubSubnav}
                                        handleToggleSubSubnav={
                                            handleToggleSubSubnav
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default NavMobileMenu
